import { ProColumns } from "@ant-design/pro-components";
import { Button } from "antd";

import { deleteFeedback } from "./api";
import { FeedbackItem } from "./types";

export const columns: ProColumns<FeedbackItem>[] = [
  {
    title: "反馈内容",
    dataIndex: "content",
    ellipsis: true,
    search: false,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    valueType: "date",
    ellipsis: true,
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    valueType: "date",
    ellipsis: true,
  },
  {
    title: "操作",
    search: false,
    render(dom, entity, index, action) {
      return (
        <Button
          onClick={() => {
            deleteFeedback(entity.id);
            action?.reload();
          }}
          disabled
        >
          DEL
        </Button>
      );
    },
  },
];
