import lodash from "lodash-es";

import { get, post } from "@/common/request";
import { ProtableRequestProps } from "@/common/types";

import { RecordItem, RequestData, ResponseData } from "./types";

export const tableRequest: ProtableRequestProps<RecordItem> = async ({
  current,
  ...params
}) => {
  const { list, total } = await post<RequestData, ResponseData>(
    "/records/pageQuery",
    { ...lodash.pickBy(params), pageNumber: current },
  );
  return { data: list, success: true, total };
};

export const deleteRecord = async (id: string | number) =>
  await get(`/records/delete/${id}`);
