import "@/common/styles/globals.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ThemeProvider from "@/components/theme";

import { routers } from "./router";

function App() {
  const router = createBrowserRouter(routers);
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
