import { SizeType } from "antd/es/config-provider/SizeContext";

export interface MicroAppGlobalDataProps {
  microAppConfig: {
    theme: {
      primaryColor?: string;
      borderRadius?: number;
      componentSize?: SizeType;
      isDark?: boolean;
      isCompact?: boolean;
      [key: string]: any;
    };
  };
  user: Record<string, any>;
  [key: string]: any;
}
/** 微前端 子应用 */
export enum MICROAPP_MODULE_NAME_ENUM {
  MODULE_APP = "backend-module-app",
}
