import { FC } from "react";

import BaseAppGlobalDataListening from "./BaseAppGlobalDataListening";
import BaseAppRouterListening from "./BaseAppRouterListening";

/**
 * ## 子应用初始化组件
 *
 * **template**
 */
const MicroAppInit: FC = () => {
  return (
    <>
      <BaseAppGlobalDataListening />
      <BaseAppRouterListening />
    </>
  );
};
export default MicroAppInit;
