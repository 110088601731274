// import { Spin } from "antd";
import { memo, ReactNode, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";

import Feedback from "@/pages/feedback";
import Record from "@/pages/record";
import User from "@/pages/user";
import Welcome from "@/pages/welcome";

import MicroAppInit from "./microapp/microapp-components/microapp-init";

// 延迟加载组件
// const importLazyComponent = (path: string): ReactNode => {
//   const SyncComponent = lazy(() => import(path));
//   const LoadComponent = (
//     <div className="w-full h-full flex flex-col items-center justify-center">
//       <Spin spinning />
//     </div>
//   );
//   return (
//     <Suspense fallback={LoadComponent}>
//       <SyncComponent />
//     </Suspense>
//   );
// };
const RouterRoot = memo(() => {
  return (
    <>
      <MicroAppInit />
      <Outlet />
    </>
  );
});
// 延迟加载组件
function LazyComponent({ element }: { element: ReactNode }) {
  return <Suspense>{element}</Suspense>;
}

export const routers: RouteObject[] = [
  {
    path: "/",
    element: <RouterRoot />,
    children: [
      {
        path: "/welcome",
        element: <LazyComponent element={<Welcome />} />,
      },
      {
        path: "/record",
        element: <LazyComponent element={<Record />} />,
      },
      {
        path: "/user",
        element: <LazyComponent element={<User />} />,
      },
      {
        path: "/feedback",
        element: <LazyComponent element={<Feedback />} />,
      },
      {
        path: "/",
        element: <LazyComponent element={<Welcome />} />,
      },
    ],
  },
];
