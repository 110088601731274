import React, { FC, PropsWithChildren } from "react";

import styles from "./index.module.scss";

interface IProps {
  title?: string;
}
/** 页面容器 */
const PageContainer: FC<PropsWithChildren<IProps>> = ({ title, children }) => {
  const PageContainerTitle = title && (
    <div className={styles.title}>{title}</div>
  );
  return (
    <div className={styles.pageContainer}>
      {PageContainerTitle}
      {children}
    </div>
  );
};

export default PageContainer;
