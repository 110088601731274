import lodash from "lodash-es";

import { get, post } from "@/common/request";
import { ProtableRequestProps } from "@/common/types";

import { FeedbackItem, RequestData, ResponseData } from "./types";

export const tableRequest: ProtableRequestProps<FeedbackItem> = async ({
  current,
  ...params
}) => {
  const { list, total } = await post<RequestData, ResponseData>(
    "/app/feedback/pageQuery",
    {
      ...lodash.pickBy(params),
      pageNumber: current,
    },
  );
  return { data: list, success: true, total };
};

export const deleteFeedback = async (id: string) =>
  await get(`/app/feedback/delete/${id}`);
