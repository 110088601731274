import { useEffect } from "react";

import {
  MICROAPP_MODULE_NAME_ENUM,
  MicroAppGlobalDataProps,
} from "@/microapp/microapp-types";

/** 获取微前端事件中心对象 */
export const getMicroappEventCenter = (
  val: MICROAPP_MODULE_NAME_ENUM = MICROAPP_MODULE_NAME_ENUM.MODULE_APP,
) => window && window?.eventCenter?.[val];

/** 监听基座下发的数据变化 */
export const useMicroAppDataListener = (
  onChange?: Parameters<MicroAppDataListener>["0"],
) => {
  useEffect(() => {
    const eventCenter = getMicroappEventCenter(
      MICROAPP_MODULE_NAME_ENUM.MODULE_APP,
    );
    eventCenter?.addDataListener(
      (data: MicroAppGlobalDataProps) => onChange?.(data),
      true,
    );
  }, [onChange]);
};
/** 监听基座下发的全局数据变化 */
export const useMicroAppGlobalDataListener = (
  onChange?: (data: MicroAppGlobalDataProps) => void,
) => {
  useEffect(() => {
    const eventCenter = getMicroappEventCenter(
      MICROAPP_MODULE_NAME_ENUM.MODULE_APP,
    );
    eventCenter?.addGlobalDataListener(
      (data: MicroAppGlobalDataProps) => onChange?.(data),
      true,
    );
  }, [onChange]);
};
