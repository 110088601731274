import { ProColumns } from "@ant-design/pro-components";
// import { Button } from "antd";
import dayjs from "dayjs";

import { UserItem } from "./types";

export const columns: ProColumns<Partial<UserItem>>[] = [
  {
    title: "名称",
    dataIndex: "displayName",
    ellipsis: true,
  },
  {
    title: "账号",
    dataIndex: "account",
    copyable: true,
    ellipsis: true,
  },
  {
    title: "邮箱",
    dataIndex: "email",
    copyable: true,
    ellipsis: true,
  },
  {
    title: "手机号",
    dataIndex: "phone",
    copyable: true,
    ellipsis: true,
  },
  {
    title: "设备",
    dataIndex: ["devices", 0, "utsname", "machine"],
    ellipsis: true,
    search: false,
  },
  {
    title: "时间",
    ellipsis: true,
    search: false,
    render(_, entity) {
      const { createTime, updateTime, lastLoginTime } = entity ?? {};
      const formatTime = (v?: number | string) =>
        v ? dayjs(v).format("YYYY/MM/DD") : "-";
      return (
        <div className="flex flex-col gap-1">
          <div>创建时间：{formatTime(createTime)}</div>
          <div>更新时间：{formatTime(updateTime)}</div>
          <div>登录时间：{formatTime(lastLoginTime)}</div>
        </div>
      );
    },
  },
  // {
  //   title: "操作",
  //   search: false,
  //   render(dom, entity, index, action) {
  //     return (
  //       <Button
  //         onClick={() => {
  //           action?.reload();
  //         }}
  //         danger
  //       >
  //         DEL
  //       </Button>
  //     );
  //   },
  // },
];
