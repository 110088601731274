import { ConfigProvider } from "antd";
// import { theme as antdTheme } from "antd";
import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren } from "react";

/**
 * ## 全局主题
 */
const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ConfigProvider>{children}</ConfigProvider>;
};

export default observer(ThemeProvider);
