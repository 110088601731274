import { ProTable } from "@ant-design/pro-components";
import { FC, memo } from "react";

import PageContainer from "@/components/page-container";

import { tableRequest } from "./api";
import { columns } from "./contsants";
import { RecordItem } from "./types";

const Record: FC = () => {
  return (
    <PageContainer title="App Record">
      <ProTable<RecordItem>
        columns={columns}
        scroll={{ x: true }}
        request={tableRequest}
        search={false}
      />
    </PageContainer>
  );
};

export default memo(Record);
