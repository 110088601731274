import { notification } from "antd";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios";
import { forIn, pickBy } from "lodash-es";
type RequestProps = <RequestProps = Record<string, any>, ResponseProps = any>(
  url: string,
  data?: RequestProps,
  config?: AxiosRequestConfig,
) => Promise<ResponseProps>;

// 全部子应用请求收口一个实例
const getRequest = () => {
  let instance: AxiosInstance;
  const config: CreateAxiosDefaults = {
    baseURL: "https://adminapi.recordx.app/",
  };

  try {
    instance = axios.create(config);
  } catch (error: any) {
    throw new Error(error);
  }
  /** request interceptors */
  const requestInterceptor = (
    config: InternalAxiosRequestConfig<any>,
  ): InternalAxiosRequestConfig<any> => {
    const newConfig = config;
    const token = localStorage.getItem("token");
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  };

  /** response interceptors */
  const responseInterceptor = (
    response: AxiosResponse<any, any>,
  ): AxiosResponse<any, any> | Promise<AxiosResponse<any, any>> =>
    response?.data?.data ?? {};
  const responseRejectInterceptor = (error: any) => {
    notification.error({
      message: "错误",
      description: JSON.stringify(error?.message || "-"),
    });
    throw new Error(error);
  };
  /** use interceptors */
  instance.interceptors.request.use((config) => requestInterceptor(config));
  instance.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => responseRejectInterceptor(error),
  );

  /** get 请求 */
  const get: RequestProps = (url, data, config) => {
    /** 格式化get请求参数 */
    const formatRequestData = (data?: any): string => {
      if (!data || typeof data !== "object") return "";
      const reson: string[] = [];
      forIn(pickBy(data), (val, key) => reson.push(`${key}=${val}`));
      return reson.length > 0 ? `?${reson.join("&")}` : "";
    };
    const params = formatRequestData(data);
    return instance.get(`${url}${params}`, config);
  };

  /** post 请求 */
  const post: RequestProps = (url, data, config) =>
    instance.post(url, data, config);

  return { get, post };
};

export const { get, post } = getRequest();
