import { ProTable } from "@ant-design/pro-components";
import { FC, memo } from "react";

import PageContainer from "@/components/page-container";

import { tableRequest } from "./api";
import { columns } from "./contsants";

const Feedback: FC = () => {
  return (
    <PageContainer title="App Feedback">
      <ProTable columns={columns} scroll={{ x: true }} request={tableRequest} />
    </PageContainer>
  );
};

export default memo(Feedback);
