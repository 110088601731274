import { observer } from "mobx-react-lite";
import { FC } from "react";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";
import { MicroAppGlobalDataProps } from "@/microapp/microapp-types";

import { useMicroAppGlobalDataListener } from "./hooks";

/** 获取全局数据 */
export const getMicroappGlobalData = () => window?.microApp?.getGlobalData();

/** 发送全局数据 */
export const setMicroappGlobalData = (data = {}) =>
  window?.microApp?.setGlobalData(data);

/**
 * ## 配置监听组件
 *
 * **监听基座配置变更，触发子应用更新。**
 *
 */
const BaseAppGlobalDataListening: FC = () => {
  // 监听基座下发的数据变化
  const onChange = (data: MicroAppGlobalDataProps) => {
    microappGlobalDataStore.changeGlobalData(data);
  };
  useMicroAppGlobalDataListener(onChange);
  return null;
};

export default observer(BaseAppGlobalDataListening);
