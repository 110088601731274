import { ProTable } from "@ant-design/pro-components";
import { FC, memo } from "react";

import PageContainer from "@/components/page-container";

import { tableRequest } from "./api";
import { columns } from "./contsants";

const User: FC = () => {
  return (
    <PageContainer title="App User">
      <ProTable columns={columns} request={tableRequest} scroll={{ x: true }} />
    </PageContainer>
  );
};

export default memo(User);
