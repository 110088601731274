import { FC } from "react";

import reactLogo from "@/assets/react.svg";

const Welcome: FC = () => {
  return (
    <div>
      <img width={100} height={100} src={reactLogo} alt="" />
    </div>
  );
};

export default Welcome;
