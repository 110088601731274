import { ProColumns } from "@ant-design/pro-components";
import { Button, Image } from "antd";

import { deleteRecord } from "./api";
import { RecordItem } from "./types";

export const columns: ProColumns<RecordItem>[] = [
  {
    title: "id",
    dataIndex: "id",
    ellipsis: true,
    search: false,
    width: "10%",
  },
  {
    title: "content",
    dataIndex: "content",
    ellipsis: true,
  },
  {
    title: "like",
    dataIndex: "like",
    ellipsis: true,
  },
  {
    title: "mediaType",
    dataIndex: "mediaType",
    ellipsis: true,
  },
  {
    title: "mediaList",
    dataIndex: "mediaList",
    ellipsis: true,
    render(dom, entity) {
      if (entity.mediaType !== "image") return;
      return (
        <div
          style={{
            width: "50px",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Image.PreviewGroup>
            {entity.mediaList.map((imgSrc) => (
              <Image width={50} height={50} src={imgSrc} />
            ))}
          </Image.PreviewGroup>
        </div>
      );
    },
  },
  {
    title: "所属人",
    dataIndex: ["owner", "displayName"],
    ellipsis: true,
  },
  {
    title: "所属人",
    dataIndex: ["owner", "email"],
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    valueType: "date",
    ellipsis: true,
    search: false,
    width: "10%",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    valueType: "date",
    ellipsis: true,
    search: false,
    width: "10%",
  },
  {
    title: "opration",
    search: false,
    width: "10%",
    render(dom, entity, index, action) {
      return (
        <Button
          onClick={() => {
            deleteRecord(entity.id);
            action?.reload();
          }}
          type="primary"
        >
          DEL
        </Button>
      );
    },
  },
];
